.logo-loading {
  width: 50px;
  height: auto;
  margin-bottom: 20px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0c46a0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  font-size: 1.5rem;
  color: #0c46a0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-style: italic;
  text-align: center;
}
