/* global.css */
@import url("https://fonts.googleapis.com/css2?family=Righteous&family=Poppins:wght@400;500;600;700&family=Manrope:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Manrope", "Righteous", Arial, sans-serif;
}

.top-app-container {
  display: flex;
  flex-direction: column;
  gap: 0%;
}

body {
  font-size: 16px;
  background-color: #ffffff;
  padding-top: 85px;
}

a {
  color: #07080a;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 500;
}

button {
  cursor: pointer;
}

input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

.pink {
  color: #f50057;
}

.blue {
  color: #0c46a0;
}
